import { Component, OnInit } from '@angular/core';
import { LayoutService } from "../../layout.service";
import { UserService } from 'app/shared/user';

declare var $;
@Component({
  selector: 'sa-collapse-menu',
  templateUrl: './collapse-menu.component.html'
})
export class CollapseMenuComponent implements OnInit {

  public userInfoDetails: any;
  public alreadySetStore: boolean = false;

  constructor(private userService: UserService, private layoutService: LayoutService) {
  }

  onToggle() {
    this.layoutService.onCollapseMenu()
  }

  ngOnInit() {
    this.userInfoDetails = this.userService.UserInfoDetails.subscribe((value) => {
      if (value && value.StoreID && value.StoreID != '00000000-0000-0000-0000-000000000000') {
        this.alreadySetStore = true;
        this.layoutService.onCollapseMenu(false);
        $('body').removeClass('hideAsside');
      } else {
        $('body').addClass('hideAsside');
        this.layoutService.onCollapseMenu();
        this.alreadySetStore = false;
      }
    })
  }
}
