import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-accept-return',
  templateUrl: './accept-return.component.html',
  styleUrls: ['./accept-return.component.css']
})
export class AcceptReturnComponent implements OnInit {
  public status: any;
//  public cancelEvent: EventEmitter<any> = new EventEmitter<any>();
  public acceptEvent: EventEmitter<any> = new EventEmitter<any>();


  constructor( public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  closeModal() {
    this.acceptEvent.emit(false);
  
  }

  submit(){
   this.acceptEvent.emit(true);
  }
  
}
