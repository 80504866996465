
export class Common {
}

export enum LanguageEnum {
    English = "en",
    Arabic = "ae"
}

export const WeekDays = [{ nameEn: 'Sunday', nameAr: 'الأحد', id: 7, selected: false },
{ nameEn: 'Monday', nameAr: 'الإثنين', id: 1, selected: false },
{ nameEn: 'Tuesday', nameAr: 'الثلاثاء', id: 2, selected: false },
{ nameEn: 'Wednesday', nameAr: 'الأربعاء', id: 3, selected: false },
{ nameEn: 'Thursday', nameAr: 'الخميس', id: 4, selected: false },
{ nameEn: 'Friday', nameAr: 'الجمعة', id: 5, selected: false },
{ nameEn: 'Saturday', nameAr: 'السبت', id: 6, selected: false }]

export enum WeekDaysEnum {
    Sunday = 7,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export enum HoursOperationEnum {
    TwentyFourHour = 1,
    CustomizeTime = 2
}

export enum DaysOperationEnum {
    EveryDay = 1,
    CustomizeDay = 2
}