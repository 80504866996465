﻿//1. Import all dependencies
import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';
import { Configuration } from '../../../app.constant';
import { Observable } from 'rxjs';
import 'rxjs/Rx';
import { ServiceResponse } from '../../../core/models/service-response.model';
import { NewServiceResponse } from 'app/core/models/new-service-response.model';
import { HttpClient } from '@angular/common/http';

//2. The service class
@Injectable()
export class ProductService {
    //3. The local private variable for  storing the URL of the REST API.
    private productAddUrl: string;
    private productAddProductUrl: string;
    private getProductDetailsUrl: string;
    private getProductDropDownUrl: string;
    private getProductListUrl: string;
    private getProductListWithPaginationUrl: string;
    private updateProductUrl: string;
    private getProductInfoUrl: string;
    private updateProductStatusUrl: string;
    private getProductVariantUrl: string;
    private getVariantUrl: string;
    private addUpdateProductVariantUrl: string;
    private updateProductQuantity: string;
    private getProductVatUrl: string;
    private addUpdateVariant: string;
    private deleteVariantUrl: string;
    private UpdateVariantStatusUrl: string;
    private UpdateVariantInventoryUrl: string;
    private GetProductImagesUrl : string;
    private getTotalImagesLimit : string;
    private termsSellAkshaak : string;
    /**
     * Product Section Common data
     */
    private dropDownData: any;


    private errorMsg: string = "Some error occurred !!";
    //4. Passsing the Http dependency to the constructor to access Http functions
    constructor(private _http: HttpClient, private _serviceResponse: ServiceResponse,
        private _configuration: Configuration) {

        this.productAddUrl = `${_configuration.WebApi}api/merchant/product/add`;
        this.productAddProductUrl = `${_configuration.WebApi}api/merchant/product/addProduct`;
        this.getProductDetailsUrl = `${_configuration.WebApi}api/merchant/GetProductDetail`;
        this.getProductDropDownUrl = `${_configuration.WebApi}api/merchant/GetProductDetailDropdowns`;
        this.getProductListUrl = `${_configuration.WebApi}api/merchant/product/list`;
        this.getProductVatUrl = `${_configuration.WebApi}api/merchant/store/GetVat`;
        this.getProductListWithPaginationUrl = `${_configuration.WebApi}api/merchant/product/productlistV2`;
        this.updateProductUrl = `${_configuration.WebApi}api/merchant/product/edit`;
        this.getProductInfoUrl = `${_configuration.WebApi}api/merchant/product/productlistbyId`;
        this.updateProductStatusUrl = `${_configuration.WebApi}api/merchant/product/Productstatus`;
        this.getProductVariantUrl = `${_configuration.WebApi}api/merchant/product-variant`;
        this.getVariantUrl = `${_configuration.WebApi}api/merchant/product/GetProductVariant`;
        this.addUpdateProductVariantUrl = `${_configuration.WebApi}api/merchant/product-variant/add-upd`;
        this.updateProductQuantity = `${_configuration.WebApi}api/merchant/product/ProductQuantity`;
        this.addUpdateVariant = `${_configuration.WebApi}api/merchant/product/AddUpdateVariant`;
        this.deleteVariantUrl = `${_configuration.WebApi}api/merchant/product/DeleteProVariant`;
        this.UpdateVariantStatusUrl =  `${_configuration.WebApi}api/merchant/product/UpdateVariantStatus`;
        this.UpdateVariantInventoryUrl =  `${_configuration.WebApi}api/merchant/product/AddUpdateVariantInventory`;
        this.GetProductImagesUrl = `${_configuration.WebApi}api/Product/GetImagebyProductId`;
        this.getTotalImagesLimit = `${_configuration.WebApi}api/Akshaak/GetAppConfiguration`;
        this.termsSellAkshaak = `${_configuration.WebApi}api/Order/GetTermsConditions`;
    }

    getTermsSellAkshaakDetail(params):Observable<any>{
        return this
        ._http.get<any>(this.termsSellAkshaak + "?Id=" + params)
    }

    addProduct(product: any): Observable<ServiceResponse> {//service response
        return this._http.post<ServiceResponse>(this.productAddUrl, product)
    }

    addProductNew(product: any): Observable<ServiceResponse> {//service response
        return this._http.post<ServiceResponse>(this.productAddProductUrl, product)
    }

    updateProduct(product: any): Observable<ServiceResponse> {
        return this._http.post<ServiceResponse>(this.updateProductUrl, product)
    }

    getProductDetail(): Observable<ServiceResponse> {
        return this
            ._http.get<ServiceResponse>(this.getProductInfoUrl);
    }

    getImagesLimit(): Observable<ServiceResponse> {
        return this
            ._http.get<ServiceResponse>(this.getTotalImagesLimit);
    }


    getProductDropDownData(): Observable<NewServiceResponse> {
        return this._http.get<NewServiceResponse>(this.getProductDropDownUrl).do((res) => {
            if (res.StatusCode == 200) {
                this.dropDownData = res.Data;
            }
        })
    }

    getProductVat(){
        return this._http.get<any>(this.getProductVatUrl)
    }
    getProductList(storeId: string): Observable<ServiceResponse> {
        return this._http.get<ServiceResponse>(this.getProductListUrl + "/" + storeId)
    }

    getProductListWithPagination(params): Observable<NewServiceResponse> {
        return this._http.post<NewServiceResponse>(this.getProductListWithPaginationUrl, params)
    }


    getProductInfo(productId: string): Observable<NewServiceResponse> {
        return this._http.get<NewServiceResponse>(this.getProductInfoUrl + "?ProductId=" + productId)
    }

    updateProductStatus(params): Observable<ServiceResponse> {
        return this._http.post<ServiceResponse>(this.updateProductStatusUrl, params)
    }

    getProductVariantById(productId: string): Observable<ServiceResponse> {
        return this._http.get<ServiceResponse>(this.getProductVariantUrl + "/" + productId)
    }

    addUpdateProductVariant(productVariant: any): Observable<ServiceResponse> {
        return this._http.post<ServiceResponse>(this.addUpdateProductVariantUrl, JSON.stringify(productVariant))
    }

    updateProductVariant(productVariant: any): Observable<ServiceResponse> {
        return this._http.post<ServiceResponse>(this.updateProductQuantity, productVariant)
    }

    getVariantById(productId: string): Observable<ServiceResponse> {
        return this._http.get<ServiceResponse>(this.getVariantUrl  + "?ProductId=" + productId)
    }

    addProductVariant(variant: any): Observable<ServiceResponse> {
        return this._http.post<ServiceResponse>(this.addUpdateVariant, variant)
    }

    deleteVariant(variant: any): Observable<any> {
        return this._http.post<any>(this.deleteVariantUrl , variant);
    }

    
    UpdateVariantStatus(variant: any): Observable<any> {
        return this._http.post<any>(this.UpdateVariantStatusUrl , variant);
    }

    UpdateVariantInventory(variant: any): Observable<any> {
        return this._http.post<any>(this.UpdateVariantInventoryUrl , variant);
    }

    GetProductImages(productId : any) : Observable<any> {
        return this._http.get<ServiceResponse>(this.GetProductImagesUrl  + "?ProId=" + productId)
        
    }

    

}