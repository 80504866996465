import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-read-more',
    template: `<p class='break-wrap mb0' style='white-space: pre-wrap;' [hidden]=
    'fullText'><span [innerHtml]="rmTextShort"></span><a href='javascript:;' class='text-more' (click)='readMore(true)' [hidden]='!showMore'>{{'read_more' | translate}}</a><a href='javascript:;' (click)='readMore(false)' class='text-more' [hidden]='!showLess'>{{'less' | translate}}</a></p><p class='break-wrap mb5' style='white-space: pre-wrap' [hidden]='!fullText'>{{rmTextFull}}<a href='javascript:;' class='text-more' (click)='readMore(true)' [hidden]='!showMore'>{{'read_more' | translate}}</a><a href='javascript:;' (click)='readMore(false)' class='text-more' [hidden]='!showLess'>{{'less' | translate}}</a></p>`,
})
export class ReadMoreComponent implements OnChanges {
    @Input() text: string;
    @Output() readMoreEvent = new EventEmitter();
    fullText = true;
    showMore = false;
    showLess = false;
    rmTextShort = '';
    rmTextFull = '';
    inputWords = [];
    constructor() {
    }

    readMore(flag) {
        this.readMoreEvent.emit(flag);
        // if (flag) {
        //     this.showMore = false;
        //     this.fullText = true;
        //     this.rmTextFull = this.text;
        //     this.showLess = true;
        // } else {
        //     this.showLess = false;
        //     this.showMore = true;
        //     this.fullText = false;
        // }
    }

    ngOnChanges() {
        this.rmTextShort = this.text;
        this.rmTextFull = this.text;
        this.inputWords = this.text.split(' ');
        let actualLength = this.text.length;
        let overallLength = this.strLen(this.text);
        if (this.text.length > 100) {
            this.fullText = false;
            this.showMore = true;
            this.rmTextShort = this.text.substring(0, overallLength > actualLength ? 50 : 100) + ' ';
        } else if (this.inputWords.length > 30) {
            this.fullText = false;
            this.showMore = true;
            this.rmTextShort = this.inputWords.slice(0, 30).join(' ') + ' ';
        } else {
            if (this.rmTextShort.length > 300) {
                this.fullText = false;
                this.showMore = true;
                this.rmTextShort = this.rmTextShort.substr(0, 300) + ' '
            } else {
                const lineBreaks = this.rmTextShort.split(/\n/g)
                if (lineBreaks.length > 4) {
                    this.fullText = false
                    this.showMore = true
                    this.rmTextShort = lineBreaks.slice(0, 4).join('\n') + ' '
                }
            }
        }
    }

    strip_html_tags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/<[^>]*>/g, '');
    }


    strLen(str) {
        var count = 0;
        for (var i = 0, len = str.length; i < len; i++) {
            count += str.charCodeAt(i) < 256 ? 1 : 2;
        }
        return count;
    }
}