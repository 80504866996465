import { Component, OnInit } from '@angular/core';
import { languages } from '../languages.model';
import { I18nService } from '../i18n.service';
import { LayoutService } from '../../layout/layout.service';
import * as moment from 'moment';
import { UtilMethods } from '../../utils/util-methods';

@Component({
    selector: 'sa-language-selector',
    templateUrl: './language-selector.component.html',
})
export class LanguageSelectorComponent implements OnInit {

    public languages: Array<any>;
    public currentLanguage: any;

    constructor(private i18N: I18nService,
        private utilMethods: UtilMethods,
        private layoutService: LayoutService) {
    }

    ngOnInit() {

        this.languages = languages;
        let curLang = this.i18N.currentLanguage;
        if (curLang == 'en') {
            this.currentLanguage = this.languages[0];
            localStorage.setItem('LangId', '1')
        }
        else {
            this.currentLanguage = this.languages[1];
            localStorage.setItem('LangId', '2')
        }
        this.layoutService.onRtlCus(this.currentLanguage.key);
        moment.locale('en');
    }

    changeLanguage(language) {
        this.utilMethods.showFullScreenLoader();
        this.i18N.setLanguage(language.key);
        this.currentLanguage = language;
        if (language.key == 'en')
            localStorage.setItem('LangId', '1')
        else
            localStorage.setItem('LangId', '2')

        this.layoutService.onRtlCus(this.currentLanguage.key);
        moment.locale('en')
        this.utilMethods.hideFullScreenLoader()
    }


}