import { Injectable } from '@angular/core';
import StorageService from './storage-abstract';

@Injectable()
export class LocalStorageService extends StorageService {

    private _storage = localStorage;

    constructor() {
        super();
    }

    public get storage(): Storage {
        return this._storage;
    }

    public getStorage(): Storage {
        return this._storage;
    }
}