import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { retry } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { I18nService } from '../i18n/i18n.service';


@Injectable()
export class HttpInterceptorMiddleWare implements HttpInterceptor {

    public pendingRequests: number = 0;

    constructor(private router: Router, 
        private userService: UserService,
        private i18N: I18nService) {
    }

    public getToken() {
        return (localStorage.getItem("Token")) ? localStorage.getItem("Token") : null;
    }

    public getUserId()  {
        return (localStorage.getItem("UserID")) ? localStorage.getItem("UserID") : null;
    }

    public getLanguage() {
        return (localStorage.getItem("LangId")) ? localStorage.getItem("LangId") : this.akshaakLanguage();
    }

    public getanonymousKey() {
        return (sessionStorage.getItem("anonymous-key")) ? sessionStorage.getItem("anonymous-key") : null;
    }

    public getLatLng() {
        return (localStorage.getItem("location")) ? JSON.parse(localStorage.getItem("location")) : null;
    }

    public akshaakLanguage(){
        let curLang = localStorage.getItem("akshaak_lang");
        if (curLang == 'en') {
            //this.currentLanguage = this.languages[0];
            //localStorage.setItem('LangId', '1');
            return "1";
        }
        else {
            //this.currentLanguage = this.languages[1];
            //localStorage.setItem('LangId', '2');
            return "2";
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // check if requesting for the file within the application
        if (request.url.includes('assets')) return next.handle(request);

        let headers = {
            DeviceType: 'Web',
            Platform: 'Windows',
            LangId: this.getLanguage()
        }
        const UserId = this.getUserId();
        if (UserId)
            headers['UserId'] = UserId;
        else
            headers['UserId'] = '';

        // const token = this.getToken();
        // if (token) {
        //     headers['Authorization'] = 'Bearer ' + token;
        // }

        const location = this.getLatLng();
        if (location) {
            headers['Latitude'] = location.lat;
            headers['Longitude'] = location.lng;
        }

        const anonymousKey = this.getanonymousKey();
        if(anonymousKey){
            headers['cartKey'] = anonymousKey;
        }

        headers['countryid'] = "687b3598-cbf3-4cc8-98a4-09f878252787";
       

        request = request.clone({
            setHeaders: headers,
        });

        return next.handle(request);
    }

}