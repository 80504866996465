import { NgModule } from "@angular/core";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { MomentPipe } from "./moment.pipe";
import { TimeDirective } from "./time.directive";
import { FieldFilterPipe } from './field-filter.pipe';
import { BodyService } from "./body.service";
import { NotificationService } from "./notification.service";
import { ToggleActiveDirective } from "./toggle-active.directive";
import { UtilMethods } from './util-methods';
import { DynamicScriptLoaderService } from './fileLoader.service';
import { MessageService } from './message.service';
import { NumFormat } from './numFormat.pipe';
import { ReadMoreComponent } from "./readMore";
import { TranslateModule } from '@ngx-translate/core';
import { SearchFilterPipe } from './search-filter.pipe';

@NgModule({
  declarations: [ToggleActiveDirective, MomentPipe, TimeDirective,  FieldFilterPipe,SafeHtmlPipe, NumFormat, ReadMoreComponent, SearchFilterPipe],
  exports: [ToggleActiveDirective, MomentPipe, TimeDirective,FieldFilterPipe, NumFormat,SafeHtmlPipe, ReadMoreComponent, SearchFilterPipe],
  providers: [BodyService, NotificationService, UtilMethods, DynamicScriptLoaderService, MessageService],
  imports: [TranslateModule]
})
export class UtilsModule {
  static forRoot() {
    return {
      ngModule: UtilsModule,
      providers: [BodyService, NotificationService]
    }
  }
}
