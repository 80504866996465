import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any[], term: string): any[] {
    if(value.length != 0){
      if(value[0].ProductID){
          return value.filter((x:any) => x.NameEn.toLowerCase().startsWith(term.toLowerCase()))
      }else
          return value.filter((x:any) => x.citName.toLowerCase().startsWith(term.toLowerCase()))
    }else
      return value;
    // return value.filter((x:any) => x.citName.toLowerCase().startsWith(term.toLowerCase()) || x.industry.toLowerCase().startsWith(term.toLowerCase()))
    
  }

}
