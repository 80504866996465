import { Component, OnInit, EventEmitter } from '@angular/core';
import { I18nService } from 'app/shared/i18n';
import { LanguageEnum } from 'app/app.common';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  public notes: any;
    public getDefaultLang: string;
    public LanguageEnum = LanguageEnum;
    public closeEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(private i18N: I18nService) { }

    ngOnInit() {
        this.getDefaultLang = this.i18N.currentLanguage;
        this.i18N.getLanguageUpdates().subscribe((lang) => {
            this.getDefaultLang = lang.language;
        })
    }

    closeModal() {
        this.closeEvent.emit(true);
    }

}
