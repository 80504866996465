import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
//import { LoginInfoComponent } from "../../user/login-info/login-info.component";
import { SupportComponent } from './customer-support/support.component';
import { TermsComponent } from 'app/+merchant/+store/dialogs/terms/terms.component';
import { BsModalService } from 'ngx-bootstrap';
import { NavigationService } from './navigation.service';
import { I18nService } from 'app/shared/i18n';
import { TranslateService } from '@ngx-translate/core';
import { LanguageEnum } from 'app/app.common';

@Component({

    selector: 'sa-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
    public currentRoute;
    private modalRef: any;
    public getDefaultLang: string;
    public LanguageEnum = LanguageEnum;
    public  termsConditions;

    constructor( private i18N: I18nService,
        private _translate: TranslateService,
        private _navigationService: NavigationService,private router: Router,
        private modalService: NgbModal,  
        private _modalService: BsModalService) {

        this.currentRoute = router;
    }

    ngOnInit() {

        this.getDefaultLang = this.i18N.currentLanguage;
        this.i18N.getLanguageUpdates().subscribe((lang) => {
            this.getDefaultLang = lang.language;
        })

        this._navigationService.getTermsSellAkshaakDetail(1).subscribe(res =>{
            if(res.IsSuccess == true){
              console.log('Terms:',res)
              this.termsConditions = res.Data.TermsConditions[0];
            }
          })
     }

    getCustomerSupport() {
        this.modalRef = this.modalService.open(SupportComponent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: 'custom-class' });
        this.modalRef.componentInstance.closeEvent.subscribe(el => {
            this.modalRef.close();
        })
    }

    openTermsConditions(event) {
        event.stopPropagation();
        event.preventDefault();
        localStorage.setItem('accept', 'false');
        let modalRef = this._modalService.show(TermsComponent, Object.assign({}, { class: 'modal-dialog modal-lg' }));
        modalRef.content.termsConditions = this.termsConditions;
        
    }
    
    

}
