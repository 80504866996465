import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
    useFactory: (translate: TranslateService) => {
        let lang = localStorage.getItem('akshaak_lang') || 'en';
        if (!lang) {
            lang = translate.getBrowserLang()
            localStorage.setItem('akshaak_lang', lang)
        }

        translate.use(lang);
        let i18N = new I18nService(translate);
        i18N.currentLanguage = lang;
        i18N.setLanguage(lang);
        return i18N;
    },
    deps: [TranslateService]
})
export class I18nService {

    private subject = new Subject<any>();
    public state;
    public data: {};
    public currentLanguage: any;


    constructor(public translate: TranslateService) {
        this.state = new Subject();
    }


    subscribe(sub: any, err: any) {
        return this.state.subscribe(sub, err)
    }

    public getTranslation(phrase: string): string {
        return this.data && this.data[phrase] ? this.data[phrase] : phrase
    }

    setLanguage(language) {
        this.currentLanguage = language;
        this.translate.setDefaultLang(language);
        this.translate.use(language);
        localStorage.setItem('akshaak_lang', language)
        if (language == 'ae') {
            var link = document.createElement("link");
            link.href = 'assets/css/bootstrap-rtl.min.css';
            link.type = "text/css";
            link.rel = "stylesheet";
            link.id = 'bootstrap-rtl';
            document.body.appendChild(link);

            var link_2 = document.createElement("link");
            link_2.href = 'assets/css/styles_ar.css';
            link_2.type = "text/css";
            link_2.rel = "stylesheet";
            link_2.id = 'styles_ar';
            document.body.appendChild(link_2);
        } else {
            let stylesheet = document.getElementById('bootstrap-rtl');
            if (stylesheet)
                stylesheet.parentNode.removeChild(stylesheet);

            let stylesheet_2 = document.getElementById('styles_ar');
            if (stylesheet_2)
                stylesheet_2.parentNode.removeChild(stylesheet_2);
        }
        this.sendUpdate(language);
    }

    sendUpdate(language: string) {
        this.subject.next({ language: language });
    }

    getLanguageUpdates(): Observable<any> {
        return this.subject.asObservable();
    }
}
