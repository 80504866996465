﻿import { Injectable } from '@angular/core';
import { Configuration } from '../../../app.constant';
import { Observable, Subject, BehaviorSubject, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DashboardService {
    
    private getProductsUrl: string;
    private getCategoriesUrl: string;
    private addFavUrl: string;
    private getOrderTrackDetail: string;
    private getSearchUrl: string;
    private getCartItemsCount: string;
    private becomeSellerUrl: string;
    private shopDetail: string;
    private shopDetailById: string;
    private faqs: string;
    private termsSellAkshaak: string;
    private bannerCategory: string;
    private akshaakCollection: string;
    private getTrendingSearch: string;
    private featureProducts: string;
    private topProductSection1: string;
    private topProductSection2: string;
    private topProductSection3: string;
    private topProductSection6: string;
    private topProductSection7: string;
    private productsforCommunity: string;
    private specialGroup: string;
    private akshaakSpecial: string;
    private popularshops: string;
    private newsletter: string;
    private sellerFaqs: string;
    private bannerClickCount: string;
    private empower: string;
    private countryListHeader: string;

    constructor(private _http: HttpClient, 
        private _configuration: Configuration) {
        //this.getSearchUrl = `http://localhost:56234/api/Customer/Filter/Search`;
        this.faqs = `${_configuration.WebApi}api/Common/GetFAQ`;
        this.sellerFaqs = `${_configuration.WebApi}api/Common/GetFAQSeller`;
        this.getSearchUrl = `${_configuration.WebApi}api/Customer/Filter/SearchKeyWord`;
        this.getProductsUrl = `${_configuration.WebApi}api/Buyer/dashboard/list`;
        this.getCategoriesUrl = `${_configuration.WebApi}api/Buyer/GetShopCategory`;
        this.addFavUrl = `${_configuration.WebApi}api/buyer/cartfav/API-add`;
        this.getCartItemsCount = `${_configuration.WebApi}api/buyer/cart/TotalItems`;
        this.getOrderTrackDetail = `${this._configuration.WebApi}api/merchant/orders/OrdersTrackDetail`;
        this.shopDetail = `${_configuration.WebApi}api/Buyer/ShopDetail`;
        this.shopDetailById = `${_configuration.WebApi}api/Buyer/ShopDetailByStoreId`;
        this.becomeSellerUrl = `${_configuration.WebApi}api/user/API-AddMerchant`;
        this.termsSellAkshaak = `${_configuration.WebApi}api/Order/GetTermsConditions`;
        this.bannerCategory = `${_configuration.WebApi}api/akshaak/homev1`;
        this.akshaakCollection = `${_configuration.WebApi}api/home/AkshaakCollections`;
        this.getTrendingSearch = `${_configuration.WebApi}api/Buyer/GetTrendingSearchV1`;
        this.featureProducts = `${_configuration.WebApi}api/home/FeatureProducts`;
        this.topProductSection1 = `${_configuration.WebApi}api/home/topProductSection1`;
        this.topProductSection2 = `${_configuration.WebApi}api/home/topProductSection2`;
        this.topProductSection3 = `${_configuration.WebApi}api/home/topProductSection3`;
        this.topProductSection6 = `${_configuration.WebApi}api/home/topProductSection6`;
        this.topProductSection7 = `${_configuration.WebApi}api/home/topProductSection7`;
        this.productsforCommunity = `${_configuration.WebApi}api/home/ProductsforCommunity`;
        this.specialGroup = `${_configuration.WebApi}api/home/SpecialGroup`;
        this.popularshops = `${_configuration.WebApi}api/home/popularshops`;
        this.akshaakSpecial = `${_configuration.WebApi}api/home/AkshaakSpecial`;
        this.newsletter = `${_configuration.WebApi}api/buyer/Subscription`;
        this.bannerClickCount = `${_configuration.WebApi}api/akshaak/BannerCount`;
        this.empower = `${_configuration.WebApi}api/Akshaak/SaveEmpower`;
        this.countryListHeader = `${_configuration.WebApi}api/akshaak/ListCountry`;
    }

    public subject = new BehaviorSubject<any>("");
     sendProductDetail(productDetail: string) {
        console.log('beforeNext:',productDetail);   
        this.subject.next(productDetail);
        console.log('AfterNext:',this.subject);   
    }

    private messageSource = new BehaviorSubject<any>('');
    countryList = this.messageSource.asObservable();

    getOrderTrackDetails(params) {
        return this._http.post(this.getOrderTrackDetail, params);
    }

    sendCountryList(countries) {
        //console.log("Message:",message);
        this.messageSource.next(countries)
    }

    getBanner_Category():Observable<any>{
        return this._http.get<any>(this.bannerCategory);  
    }

    getAkshaak_Collection():Observable<any>{
        return this._http.get<any>(this.akshaakCollection);  
    }

    getCountryList():Observable<any>{
        return this._http.get<any>(this.countryListHeader);  
    }

    getHomePageData():Observable<any>{
        let homePageDetail = forkJoin(
            this._http.get<any>(this.featureProducts).share(),
            this._http.get<any>(this.topProductSection1).share(),
            this._http.get<any>(this.topProductSection2).share(),
            this._http.get<any>(this.topProductSection3).share(),
            this._http.get<any>(this.productsforCommunity).share(),
            this._http.get<any>(this.specialGroup).share(),
            this._http.get<any>(this.topProductSection6).share(),
            this._http.get<any>(this.topProductSection7).share(),
            this._http.get<any>(this.popularshops).share(),
            this._http.get<any>(this.akshaakSpecial).share(),
            this._http.get<any>(this.getTrendingSearch).share()
        );
        //console.log('HomeData:',homePageDetail)
        return homePageDetail;
    }

    getfeatureProducts():Observable<any>{
        return  this._http.get<any>(this.featureProducts)   
    }

    getTopProductSection1():Observable<any>{
        return  this._http.get<any>(this.topProductSection1)
    }

    getTopProductSection2():Observable<any>{
        return this._http.get<any>(this.topProductSection2)
    }

    getTopProductSection3():Observable<any>{
        return this._http.get<any>(this.topProductSection3)
    }

    getTopProductSection6():Observable<any>{
        return this._http.get<any>(this.topProductSection6)
    }

    getTopProductSection7():Observable<any>{
        return this._http.get<any>(this.topProductSection7)
    }

    getPopularshops():Observable<any>{
        return this._http.get<any>(this.popularshops)
    }

    getAkshaakSpecial():Observable<any>{
        return this._http.get<any>(this.akshaakSpecial)
    }

    getHomeTrendingSearch():Observable<any>{
        return this._http.get<any>(this.getTrendingSearch)
    }

    getProductsforCommunity():Observable<any>{
        return this._http.get<any>(this.productsforCommunity)
    }

    getSpecialGroup():Observable<any>{
        return  this._http.get<any>(this.specialGroup)
    }
    
    getSellerFaqs():Observable<any>{
        return this
        ._http.get<any>(this.sellerFaqs + "?Id=" + 2)
    }

    getItemsCountCart(params):Observable<any>{
        return this
        ._http.get<any>(this.getCartItemsCount + "?UserId=" + params)
    }

    subscribeToNewsLetter(params):Observable<any>{
        return this
        ._http.get<any>(this.newsletter + "?Email=" + params)
    }

    getFaqs():Observable<any>{
        return this
        ._http.get<any>(this.faqs + "?Id=" + 2)
    }

    getSearchList(params):Observable<any>{
        return this
        ._http.get<any>(this.getSearchUrl + "?KeyWord=" + params)
    }

    getTermsSellAkshaakDetail(params):Observable<any>{
        return this
        ._http.get<any>(this.termsSellAkshaak + "?Id=" + params)
    }
    
    getCategories():Observable<any>{
        return this
        ._http.get<any>(this.getCategoriesUrl);
    } 

    getProductsData(): Observable<any> {
        return this
            ._http.get<any>(this.getProductsUrl);
    } 

    addFavorites(params): Observable<any> {
        return this
        ._http.post<any>(this.addFavUrl, params)
    }

    bannerCount(params): Observable<any> {
        return this
        ._http.post<any>(this.bannerClickCount, params)
    }

    becomeSeller(userId: string): Observable<any> {
        return this
        ._http.get<any>(this.becomeSellerUrl + "?UserId=" + userId);
    }

    getStoreInfo(shopName){
        return this
        ._http.get<any>(this.shopDetail + "?storeName=" + shopName);
    }

    getStoreInfoById(storeId: string): Observable<any> {
        return this._http.get<any>(this.shopDetailById + "?StoreId=" + storeId)
    }

    saveEmpower(params): Observable<any> {
        return this
        ._http.post<any>(this.empower, params)
    }
}