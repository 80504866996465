import { Component, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-model',
  templateUrl: './model.component.html',
  styleUrls: ['./model.component.scss']
})
export class ModelComponent implements OnInit {

  public CartDetails: any;
  public previousDelName : string;
  public delName : string;
  public shopslist: [];
  public ratingValue: any;
  public userReview : string;
 
  constructor() { }

  public submitEvent: EventEmitter<any> = new EventEmitter<any>();
  public registerEvent: EventEmitter<any> = new EventEmitter<any>();
  public closeEvent: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit() {

    console.log(this.CartDetails);
      if(this.CartDetails.type === 'pickUp'){
      this.deliveryType();  
      } 
  }

  deliveryType(){
    if(this.CartDetails.previousDelType == 1){
      this.previousDelName = 'Akshaak Delivery';
    }
    if(this.CartDetails.previousDelType == 2){
      this.previousDelName = 'Customer Pickup';
    }  
    if(this.CartDetails.previousDelType == 3){
      this.previousDelName = 'Shop Delivery';
    }
    if(this.CartDetails.delType == 1){
      this.delName = 'Akshaak Delivery';
    }   
    if(this.CartDetails.delType == 2){
      this.delName = 'Customer Pickup';
    }
    if(this.CartDetails.delType == 3){
      this.delName = 'Shop Delivery';
    }
  }

  submitModal() {
    this.submitEvent.emit(true);
  }
  registerModal() {
    this.registerEvent.emit(true);
  }
  closeModal() {
    this.closeEvent.emit(true);
  }

  rating(event){
    console.log('Rating:',event);
    this.ratingValue = event;
  }

}
