import { Component, OnInit, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent implements OnInit {


  constructor(private sanitizer: DomSanitizer) { }
  public url: any;
  public closeEvent: EventEmitter<any> = new EventEmitter<any>();
  public pdfUrl: any;

  ngOnInit() {
    this.getPdfUrl();
  }

  getPdfUrl() {
    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  closeModal() {
    this.closeEvent.emit(true);
  }
}
