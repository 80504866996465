import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";

@Injectable()
export class Configuration {

    public API_BASEURL: string;
    public WebApi: string;
    public Web: string;
    public url: string = '';

    constructor(@Inject(DOCUMENT) private document) {
        let domain = document.location.hostname.split(':')[0];
        this.url = document.location.protocol + '//' + domain;
        //  this.WebApi = `${this.url}:56234/`;
        // this.WebApi = `http://134.213.63.182:8038/`;
        // this.API_BASEURL = `http://134.213.63.182:8038/`;
        // this.WebApi = `http://eventoclients.com:8038/`;
        this.WebApi = `https://staging.akshaak.com/v1/`;
        this.API_BASEURL = `https://staging.akshaak.com/`;
        // this.WebApi = `https://staging.akshaak.com/bh/`;
        // this.API_BASEURL = `https://staging.akshaak.com/`;
        // this.WebApi = `https://35.181.156.11:7090/v1/`;
        // this.API_BASEURL = `https://35.181.156.11:7090/`;
        // this.WebApi = `https://beta.akshaak.com/v1/`;
        // this.API_BASEURL = `https://beta.akshaak.com/`;
        // this.WebApi = `https://akshaak.com/v1/`;
        // this.API_BASEURL = `https://akshaak.com/`;  
        // this.WebApi = `https://akshaak.com/v2/`;
        // this.API_BASEURL = `https://akshaak.com/`;  
        // this.WebApi = `https://demo.akshaak.com/v1/`;
        // this.API_BASEURL = `https://demo.akshaak.com/`;
        // this.WebApi = `https://staging1.akshaak.com/v1/`;
        // this.API_BASEURL = `https://staging1.akshaak.com/`;      
    }
}