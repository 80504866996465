import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { map, switchMap, filter } from 'rxjs/operators';
import { UserService } from './shared/user';
// import { ChatPaginationService } from './+merchant/chats/chat.service';
import { LanguageEnum } from './app.common';
import { I18nService } from 'app/shared/i18n';
import { OneSignalService } from 'ngx-onesignal';

const APP_TITLE = 'All our Products are Handmade and Homemade at UAE | Akshaak';
const SEPARATOR = ' > ';
@Component({
    selector: 'app-root',
    template:
        `<ngx-spinner name="MAIN_APP_LOADER" fullScreen="false" bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff"
            type="ball-scale-multiple">
            <p style="font-size: 20px; color: white"></p>
        </ngx-spinner>
        <router-outlet (activate)="onActivate()" ></router-outlet>`
})
export class AppComponent implements OnInit {

    private LanguageEnum = LanguageEnum;
    public getDefaultLang: string;
    public showingFullScreenLoader: boolean;
    private userDetails: any;

    public constructor(private router: Router,
        private userService: UserService,
        private i18N: I18nService,
        // private chatService: ChatPaginationService,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private metaTagService: Meta
    ) {
        this.titleService.setTitle("Akshaak - Best Handmade Products in UAE. Shop Now!");

        this.metaTagService.addTags([
            {
                name: 'keywords', content: "buy handmade products, handmade products in uae"
            }
            
        ]);
        this.metaTagService.updateTag({
            name: 'description', content:"Best Online Marketplace for Handmade products in UAE. All our products are Handmade & Homemade Whatever you need ,you find it Here. BUY NOW!"            
        });
    }

    ngOnInit() {

        this.getDefaultLang = this.i18N.currentLanguage;
        this.i18N.getLanguageUpdates().subscribe((lang) => {
            this.getDefaultLang = lang.language;
        })
        
        
        /**
         * Refresh session if required
         */
        let details = this.userService.getCurrentUser();
        if (details) {
            this.userService.getUpdatedInfo();
            this.userDetails = this.userService.getCurrentUser();
            // if (!localStorage.getItem('akshaak_firebase_token'))
            //     this.getPushNotificationsPermission(this.getDefaultLang);
        }

        this.onActivate();

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map(route => route.firstChild),
            switchMap(route => route.data),
            map((data) => {
                if (data.titleEn) {
                    // If a route has a title set (e.g. data: {title: "Foo"}) then we use it
                    return data.title;
                } else {
                    // If not, we do a little magic on the url to create an approximation
                    return this.router.url.split('/').reduce((acc, frag) => {
                        if (acc && frag) { acc += SEPARATOR; }
                        return this.router.url.split('/').reduce((acc, frag) => {
                            if (acc && frag) { acc += SEPARATOR; }
                            return acc + this.ucFirst(frag);
                        });
                    });
                }
            })
        )
            .subscribe((pathString) => this.titleService.setTitle(`${APP_TITLE} ${pathString}`));


    }

    ucFirst(string) {
        if (!string) { return string; }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // getPushNotificationsPermission(lang) {
    //     this.chatService.requestPermission(this.userDetails.StoreID, lang);
    // }

    onActivate() {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}