// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBhiwzo7JfanK2Z648XMqpXsioH5bq1sPE",
    authDomain: "akshaak-1561447798685.firebaseapp.com",
    databaseURL: "https://akshaak-1561447798685.firebaseio.com",
    projectId: "akshaak-1561447798685",
    storageBucket: "akshaak-1561447798685.appspot.com",
    messagingSenderId: "864205900432",
    appId: "1:864205900432:web:1abde65d6de7548d"
  }
};
