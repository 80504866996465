import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ngbd-rating-basic',
  templateUrl: './ngbd-rating-basic.component.html',
  styleUrls: ['./ngbd-rating-basic.component.scss']
})
export class NgbdRatingBasicComponent implements OnInit {
  public currentRate = 0;
  @Output() rating = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  sendRating(){
    
    this.rating.emit(this.currentRate);
  }


}
