import { NgModule } from "@angular/core";

import { LanguageSelectorComponent } from "./language-selector/language-selector.component";
import { I18nPipe } from "./i18n.pipe";
//import {I18nService} from "./i18n.service";
import { CommonModule } from "@angular/common";
import { BsDropdownModule } from "ngx-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        BsDropdownModule,
        TranslateModule
    ],
    declarations: [
        LanguageSelectorComponent,
        I18nPipe
    ],
    exports: [
        LanguageSelectorComponent,
        I18nPipe,
        TranslateModule
    ],
    //providers: [I18nService]

})
export class I18nModule {
    //static forRoot() {
    //    return {
    //        ngModule: I18nModule,
    //        providers: [I18nService]
    //    }
    //}
}
