import { Pipe, Injectable, PipeTransform } from "@angular/core";

@Pipe({ name: "numformat" })
@Injectable()
export class NumFormat implements PipeTransform {
    transform(value): any {
        try {
            value = Number(value);
            if (value)
                return parseFloat(value).toFixed(2)
            else
                return '0.00';
        } catch (e) {
            return '0.00';
        }
    }
}