import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LogoutComponent } from "./logout/logout.component";
import { LocalStorageService } from '../storage/storage.service';
import { TranslateModule } from "@ngx-translate/core";
import { LazyLoadImageModule, intersectionObserverPreset } from 'ng-lazyload-image';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { ConsumerLayoutsModule } from "../layout/consumer-layouts/consumer-layouts.module";
import { ProductService } from "app/+consumer/+product/+services/product.service";
import { DashboardService } from "app/+consumer/+dashboard/services/dashboard.service";
@NgModule({
  imports: [
    CommonModule,
    ConsumerLayoutsModule,
    TranslateModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset
  })
],
  declarations: [LogoutComponent, UserGuideComponent],
  exports: [LogoutComponent],
  providers: [LocalStorageService,ProductService,DashboardService]
})
export class UserModule { }
