import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { I18nService } from 'app/shared/i18n';
import { LanguageEnum } from 'app/app.common';


@Component({
    selector: 'modal-update-status',
    templateUrl: './update-status.component.html',
    styleUrls: ['./update-status.component.css']
})
export class UpdateStatusComponent implements OnInit {

    public getDefaultLang: string;
    public LanguageEnum = LanguageEnum;
    public currentStatus: string;
    public orderType: string;
    public closeEvent: EventEmitter<any> = new EventEmitter<any>();
    public showComplete: boolean;

    constructor(public bsModalRef: BsModalRef,
        private i18N: I18nService) { }

    ngOnInit() {
        this.getDefaultLang = this.i18N.currentLanguage;
        this.i18N.getLanguageUpdates().subscribe((lang) => {
            this.getDefaultLang = lang.language;
        })
    }

    hideDialog(flag, statusId) {
        this.closeEvent.emit({ changed: flag, statusId: statusId });
    }

}