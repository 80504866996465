import { Component, OnInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { UtilMethods } from 'app/shared/utils/util-methods';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/shared/user';
import { UserTokenService } from 'app/+auth/+services/user-token.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {
  public UserDetails: any;
  public closeEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(private _router: Router,
    private _userTokenService: UserTokenService,
    private _userService: UserService,
    private _translate: TranslateService,
    private cd: ChangeDetectorRef,
    private utilMethods: UtilMethods) { }

  ngOnInit() {
    this.RequestForOTP();
  }
  otpModel: any = {
    input1: '',
    input2: '',
    input3: '',
    input4: '',
    input5: '',
    input6: ''
}

userBasicInfo: any = {

    Email: "",
    Password: ""
}

// onKeyPress(event,  nextEl) {
//     if (event.keyCode >= 48 && event.keyCode <= 57) {
//         if(nextEl)
//         nextEl.focus();
//     } 
//     else
//      return false;
//     this.cd.detectChanges();
   
// }
// onKeyUp(event, lastEl) {
//     if (lastEl && event.keyCode == 8) 
//         lastEl.focus();
       
// }
onKeyPress(event) {
    let element = event.srcElement.nextElementSibling; // get the sibling element
    let prevElement = event.srcElement.previousElementSibling;
    if (event.keyCode == 8 && prevElement != null) {
        event.srcElement.value = ''
        prevElement.focus();
    }
    else if (event.keyCode >= 48 && event.keyCode <= 57 && element != null)
        element.focus();
    else
        return
}


getAllData() {

    this.userBasicInfo.Email = this._userTokenService.regEmail;
    this.userBasicInfo.Password = this._userTokenService.regPassword;
    this._userTokenService.getLoginInfoDetails(this.userBasicInfo).subscribe(
        srSuccess => {
            this.utilMethods.hideLoader();
            this._userService.setCurrentUser(srSuccess.Data);
            if (srSuccess.Data.StoreID == "00000000-0000-0000-0000-000000000000") {
                this._router.navigate(['/merchant/shop/add']);
            }
        }, (error) => {
            this.utilMethods.hideLoader();
        }
    )
}
closeModal() {
  this.closeEvent.emit({ changed: true });
 
}
async RequestForOTP() {
    this.utilMethods.showLoader();
    this._userTokenService.otpConfirmation(this.UserDetails.phoneNo, "",  this.UserDetails.name)
        .subscribe(async (srSuccess) => {
            this.utilMethods.hideLoader();
            let msg = await this._translate.get('otp_sent_email').toPromise<void>();
            this.utilMethods.notificationSuccess(msg)
            //console.log(srSuccess);
        }, (srError) => {
            this.utilMethods.hideLoader();
            //console.log(srError);
        })
}

resendCode() {
    this.RequestForOTP();
}

async confirmation() {
    if (this.otpModel.input1 == '' || this.otpModel.input2 == '' || this.otpModel.input3 == '' || this.otpModel.input4 == '' || this.otpModel.input5 == '' || this.otpModel.input6 == '') {
        let msg = await this._translate.get('six_digit_otp').toPromise<void>();
        this.utilMethods.notificationError(msg);
        return;
    }
    else {
        this.utilMethods.showLoader();
        var otpCode = this.otpModel.input1 + this.otpModel.input2 + this.otpModel.input3 + this.otpModel.input4 + this.otpModel.input5 + this.otpModel.input6;
        this._userTokenService.verifyProfileOtp(this.UserDetails.phoneNo, otpCode)
            .subscribe(async srSuccess => {
                this.utilMethods.hideLoader();
                if (srSuccess.IsSuccess == false) {
                    let msg = await this._translate.get('invalid_verification_otp').toPromise<void>();
                    this.utilMethods.notificationDataError(msg);
                }
                else {
                    this._userService.setCurrentUser(srSuccess.Data);
                    let msg = await this._translate.get('number_changed_successfully').toPromise<void>();
                    this.utilMethods.notificationSuccess(msg);
                     this.closeModal();
                }
            }, srError => {
                this.utilMethods.hideLoader();
            })
    }
}
}
