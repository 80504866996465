import * as $ from 'jquery';

import { Injectable } from '@angular/core';
import { UserService } from '../../../shared/user';
import { Configuration } from '../../../app.constant';
import 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private termsSellAkshaak: string;
    public subUserInfo: any;
    constructor(private router: Router, private _userService: UserService, private _http: HttpClient,
        private _configuration: Configuration) { 
        this.termsSellAkshaak = `${_configuration.WebApi}api/Order/GetTermsConditions`;
    }

    getTermsSellAkshaakDetail(params):Observable<any>{
        return this
        ._http.get<any>(this.termsSellAkshaak + "?Id=" + params)
    }

    activateRouterLink(routerUrl, subMenuId) {
        this.subUserInfo = this._userService.getCurrentUser(); 
        if (this.subUserInfo.StoreID !== "00000000-0000-0000-0000-000000000000") {
            const tree: UrlTree = this.router.parseUrl(routerUrl);
            console.log('RouterUrl:',routerUrl);
            const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
            const s: UrlSegment[] = g.segments;
            console.log("Segmennts:",s);
            //let pathId = s[subMenuId].path;
            let pathId = "#" + s[subMenuId].path;
            console.log('pathId:',pathId);
            console.log('ClassList:',document.querySelector(pathId).classList);
            if (document.querySelector(pathId).classList.value != 'active') {
                if ($('aside nav ul li').hasClass('open')) {
                    $('aside nav ul li').removeClass('open')
                    $('aside nav ul li ul').css("display", "none");
                }
                $('aside nav ul li').removeClass('active')
                $("aside " + pathId).addClass("open");
                $("aside " + pathId + ' ul').css("display", "block");
                $("aside " + pathId + ' ul  li:nth-child(' + subMenuId + ')').addClass("active");
            }
            else {
                if (document.querySelector(pathId + ' ul  li:nth-child(' + subMenuId + ')').classList.value != 'active')
                    $("aside " + pathId + ' ul  li:nth-child(' + subMenuId + ')').addClass("active");
            }
        }
    }
}