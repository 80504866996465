import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'app/+consumer/+dashboard/services/dashboard.service';
import { I18nService } from 'app/shared/i18n';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilMethods } from 'app/shared/utils/util-methods';
import { LayoutService } from 'app/shared/layout/layout.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SupportComponent } from 'app/shared/layout/navigation/customer-support/support.component';

@Component({
  selector: 'app-e-empower',
  templateUrl: './e-empower.component.html',
  styleUrls: ['./e-empower.component.scss']
})
export class EEmpowerComponent implements OnInit {

  public hideHiLogo: boolean = false;
  public getUserLocation: boolean = false;
  private modalRef: any;

  constructor(
    private dashboardService: DashboardService,
    private i18N: I18nService,
    private _activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    private formBuilder: FormBuilder,
    private utilMethods: UtilMethods,
    private modalService: NgbModal,
  ) {
    $('.loaderWrap').fadeOut();
  }
  categoriesData: any;
  getDefaultLang: any;
  language: any;
  langFlag: string;
  sellValues: any = []
  sellValuesAr: any = []
  sellValuesError: boolean = false;
  successPopup: boolean = false;
  sellingThrough: any = [
    {
      Id: 1,
      value: "Exhibitions",
      valueAr: "معارض",
      isChecked: false
    },
    {
      Id: 2,
      value: "Social Media",
      valueAr: "وسائل التواصل الاجتماعي",
      isChecked: false
    },
    {
      Id: 3,
      value: "Own website",
      valueAr: "موقع الإلكتروني الخاص بنا",
      isChecked: false
    },
    {
      Id: 4,
      value: "Other websites",
      valueAr: "مواقع اخرى",
      isChecked: false
    },
  ];
  public empowerForm: FormGroup;

  ngOnInit() {

    this._activatedRoute.params.subscribe(params => {
      this.language = params["language"];
      //console.log('Company name', this.company);
      //console.log('language:', this.language);
      if (this.language == 'ar')
        this.language = 'ae';
      //console.log('Currentlanguage:', this.i18N.currentLanguage);
      if (this.i18N.currentLanguage != this.language && (this.language == 'en' || this.language == 'ae'))
        this.translate();
    });

    this.langFlag = this.i18N.currentLanguage;
    // this.getDefaultLang = this.i18N.currentLanguage;
    this.i18N.getLanguageUpdates().subscribe((lang) => {
      this.langFlag = lang.language;
    })

    this.formInit();

    this.dashboardService.getCategories().subscribe(res => {
      this.categoriesData = res.Data.DashboardCategories;
      //console.log(res.Data);
    });

    let elem = document.getElementById("stickyBarBottom");
    let elem2 = document.getElementById("stickyBarBottomFix");
    elem.parentNode.removeChild(elem);
    elem2.parentNode.removeChild(elem2);
  }

  formInit() {
    this.empowerForm = this.formBuilder.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)])
      ],
      phonenumber: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(/^[0-9]{8}$/)
      ])),
      companyname: ['', [Validators.required]],
      tradelicensenumber: ['', [Validators.required]],
      businesstype: ['', [Validators.required]],
      tradelicensetype: ['', [Validators.required]],
      areyou: ['', [Validators.required]],
      businessrevenue: ['', [Validators.required]],
      currentlysellingthrough: ['']
    });
  }

  getCustomerSupport(param?) {
    this.modalRef = this.modalService.open(SupportComponent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: 'custom-class' });
    if (param == 1)
      this.modalRef.componentInstance.openEmailSupport();
    this.modalRef.componentInstance.closeEvent.subscribe(el => {
      this.modalRef.close();
    })
  }

  translate() {
    this.i18N.currentLanguage == 'ae' ? this.langFlag = 'en' : this.langFlag = 'ae';
    this.utilMethods.showFullScreenLoader();

    this.i18N.setLanguage(this.langFlag);
    this.layoutService.onRtlCus(this.langFlag);
    this.utilMethods.hideFullScreenLoader()
  }

  validateForm() {
    this.utilMethods.markControlsTouched(this.empowerForm);
    if (this.langFlag == 'en' && this.sellValues.length == 0) {
      this.sellValuesError = true;
      return;
    }
    if (this.langFlag == 'ae' && this.sellValuesAr.length == 0) {
      this.sellValuesError = true;
      return;
    }
    if (!this.empowerForm.valid) {
      return;
    }
    console.log(this.empowerForm.value);
    if (this.langFlag == 'en') {
      this.empowerForm.value.currentlysellingthrough = this.sellValues.toString();
    } else {
      this.empowerForm.value.currentlysellingthrough = this.sellValuesAr.toString();
    }

    this.dashboardService.saveEmpower(this.empowerForm.value).subscribe(res => {
      if (res.IsSuccess) {
        this.successPopup = true;
        this.empowerForm.reset();
        this.formInit();
        console.log(this.empowerForm.value);
        this.sellValuesAr = [];
        this.sellValues = [];
        this.sellingThrough.forEach(element => {
          element.isChecked = false;
        });
      }
      console.log("result:", res)
    })
  }

  onSellingThroughSelection(value, valueAr, isChecked, index) {
    this.sellingThrough[index].isChecked = isChecked;
    if (isChecked) {
      this.sellValues.push(value);
      this.sellValuesAr.push(valueAr);
      this.sellValuesError = false;
      // console.log("sellValues", this.sellValues.toString());
      // console.log("Form", this.empowerForm.value);
    } else {
      let index = this.sellValues.indexOf(value);
      this.sellValues.splice(index, 1);
      this.sellValuesAr.splice(index, 1);

      if (this.langFlag == 'en' && this.sellValues.length == 0) {
        this.sellValuesError = true;
      } else if (this.sellValuesAr.length == 0) {
        this.sellValuesError = true;
      }
      // console.log("sellValues", this.sellValues);
      // console.log("Form", this.empowerForm.value);
    }
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }


}
