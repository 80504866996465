import { Component, OnInit, ViewChild, HostListener, ElementRef, Input } from '@angular/core';
import { Select2OptionData } from 'ng2-select2';
import { I18nService } from 'app/shared/i18n/i18n.service';
import { UtilMethods } from 'app/shared/utils/util-methods';
import { LayoutService } from '../../layout.service';
import { DashboardService } from 'app/+consumer/+dashboard/services/dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModelComponent } from '../model/model.component';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'app/shared/utils/notification.service';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { UserService } from 'app/shared/user/user.service';
import { ProductService } from 'app/+consumer/+product/+services/product.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SupportComponent } from 'app/shared/layout/navigation/customer-support/support.component';
import { v4 as uuid } from 'uuid';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserTokenService } from 'app/+auth/+services/user-token.service';
import { AuthService, FacebookLoginProvider, GoogleLoginProvider } from "angular-6-social-login";
import { SocialUser } from "angular-6-social-login";
import {Location} from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  private user: SocialUser;
  constructor(public i18N: I18nService,
    private _productService: ProductService,
    private utilMethods: UtilMethods,
    private layoutService: LayoutService,
    private dashboardService: DashboardService,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private _translate: TranslateService,
    private _router: Router,
    private _userTokenService: UserTokenService,
    private formBuilder: FormBuilder,
    private deviceService: DeviceDetectorService,
    private activatedRoute: ActivatedRoute,
    private socialAuthService: AuthService,
    private _location: Location,
    public _userService: UserService) {
    this.epicFunction();
    $('.loaderWrap').fadeOut();
  }
  @Input() getUserLocation : boolean;
  downloadAppBar: boolean = true;
  device: string;
  deviceInfo = null;
  public langFlag: string;
  public filteredArray: Array<string> = [];
  @ViewChild('autocomplete') public autocomplete;
  @ViewChild('Navhelper') Navhelper: ElementRef<HTMLElement>;
  public data: any;
  public userId: string;
  public storeId: string;
  public merchantId: string;
  public cartCount: number;
  public userName: string;
  public userImage: string;
  public currentUser: any;
  searchLoading: boolean = false;
  isbeingSearched: boolean = false;
  selectedSearch: string;
  navigateToCheckout: string = '0';
  modalRef: any;
  categoriesData: any;
  public mobileSearchToggle: false;
  public responsiveMode: boolean = false;
  public mobileSearchBar: boolean = false;
  public failurePopup: boolean = false;
  public loginFailMsg: string = '';
  public countryList: any = [];

  public signupPopup: boolean = false;
  public signInPopup: boolean = false;
  public userForm: FormGroup;
  public loginForm: FormGroup;
  public redirectURL: string;

  ngOnInit() {
    this.langFlag = this.i18N.currentLanguage;
    this.i18N.getLanguageUpdates().subscribe((lang) => {
      this.langFlag = lang.language;
    })
    localStorage.setItem('BuyerSide', 'true');

    this.dashboardService.getCountryList().subscribe(res => {
      if(res.IsSuccess){
        this.countryList = res.Data;
        this.dashboardService.sendCountryList(this.countryList)
      }
    })

    this._productService.getFilterInfo().subscribe(res => {
      if (res.IsSuccess) {
        localStorage.setItem('Ceil', res.Data.PriceTypes.Maximum);
      }
    })

    this.checkUserInfo();
    if(this.getUserLocation != undefined){
      this.downloadAppBar = false;
    }
    
    if (this.getUserLocation == undefined && 'geolocation' in navigator) {
      let location = {
        "lat": 0,
        "lng": 0
      }
      navigator.geolocation.getCurrentPosition((position) => {
        //console.log(position);
        location.lat = position.coords.latitude;
        location.lng = position.coords.longitude;
        localStorage.setItem("location", JSON.stringify(location));
        //this.addressForm.controls.Viewport.setValue( m.geometry.viewport);
      }, (error) => {
        if (error.code == 1) {
          localStorage.setItem("location", JSON.stringify(location));
        }
        //console.log("location error", error);
      });
    }

    // if(this.currentUser == 'null'){
    // localStorage.setItem('logged', 'false');
    // }

    this.dashboardService.getCategories().subscribe(res => {
      this.categoriesData = res.Data.DashboardCategories;
      //console.log(res.Data);
    });

    // this.dashboardService.getSearchList().subscribe(res => {
    //   if(res.IsSuccess)
    //   this.filteredArray = res.Data;
    //    res.Data.forEach(item => {
    //     this.filteredArray.push(item.FilterNames);
    //   });
    // })

    if (window.innerWidth <= 991) {
      this.responsiveMode = true;
    }
    if (window.innerWidth <= 767) {
      this.mobileSearchBar = true;
    }

    $(document).ready(function () {
      $('.mobileSearchToggleBtn').click(function () {
        $(".mobileSearchBar").slideToggle();
      });
    })
  }

  // private delay(ms: number)
  // {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }

  // private async sleep()
  // {
  //   //console.log("Beforep: " + new Date().toString());
  //   // Sleep thread for 3 seconds
  //   await this.delay(500);

  //   if (localStorage.getItem('LScurrentUser')) {
  //     this.merchantId = JSON.parse(localStorage.getItem('LScurrentUser')).MerchantID;
  //     this.storeId = JSON.parse(localStorage.getItem('LScurrentUser')).StoreID;
  //     this.userId = JSON.parse(localStorage.getItem('LScurrentUser')).UserID;
  //     this.userImage = JSON.parse(localStorage.getItem('LScurrentUser')).ProfileImage;
  //     this.userName = JSON.parse(localStorage.getItem('LScurrentUser')).FirstName + ' ' + JSON.parse(localStorage.getItem('LScurrentUser')).LastName;
  //   } else if (!sessionStorage.getItem('anonymous-key')) {
  //     //console.log("UUID:", uuid());
  //     this.userId = uuid();
  //     sessionStorage.setItem('anonymous-key', this.userId);
  //   } else {
  //     this.userId = sessionStorage.getItem('anonymous-key');
  //   }

  //   //console.log("Afterp:  " + new Date().toString());
  // }

  checkUserInfo(){
    if (localStorage.getItem('LScurrentUser')) {
      this.merchantId = JSON.parse(localStorage.getItem('LScurrentUser')).MerchantID;
      this.storeId = JSON.parse(localStorage.getItem('LScurrentUser')).StoreID;
      this.userId = JSON.parse(localStorage.getItem('LScurrentUser')).UserID;
      this.userImage = JSON.parse(localStorage.getItem('LScurrentUser')).ProfileImage;
      this.userName = JSON.parse(localStorage.getItem('LScurrentUser')).FirstName + ' ' + JSON.parse(localStorage.getItem('LScurrentUser')).LastName;
    } else if (!sessionStorage.getItem('anonymous-key')) {
      //console.log("UUID:", uuid());
      this.userId = uuid();
      sessionStorage.setItem('anonymous-key', this.userId);
    } else {
      this.userId = sessionStorage.getItem('anonymous-key');
    }
    //this.sleep();

    this.dashboardService.getItemsCountCart(this.userId).subscribe(res => {
      if (res) {
        localStorage.setItem("cartCount", res.Data);
        this.cartCount = res.Data;
        //console.log(res);
      }
    })

    this.currentUser = this._userService.currentUserValue;

    this.userForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [localStorage.getItem('googleEmail') ? localStorage.getItem('googleEmail') : '', [Validators.required, Validators.email]],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6)])
      ],
      seller: [false]
    });

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.compose([
        Validators.required,
      ])
      ]
    });

    this.utilMethods.hideFullScreenLoader();

  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    if (window.innerWidth <= 991) {
      this.responsiveMode = true;
    } else {
      this.responsiveMode = false;
    }

    if (window.innerWidth <= 767) {
      this.mobileSearchBar = true;
    } else {
      this.mobileSearchBar = false;
    }
  }

  epicFunction() {
    //console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    this.device = this.deviceInfo.os;
    sessionStorage.setItem('OS',this.device);
    // console.log(this.deviceInfo);
    // console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }
  navClick(event) {
    // console.log(event.target);
    // event.preventDefault();
    // event.stopPropagation();
    // var element1 = document.getElementsByClassName("Navhelper")[0];
    // var element2 = document.getElementsByClassName("mainToggler")[0];

    // element1.classList.add("collapsed");
    // element2.classList.add("collapsed");

    // var att = document.createAttribute("aria-expanded");
    // att.value = "false";                 
    // console.log(att);
    // element1.setAttributeNode(att);
    // element2.setAttributeNode(att); 
    if (window.innerWidth <= 991) {
      let el: HTMLElement = this.Navhelper.nativeElement;
      el.click();
    }
  }

  get cartItemsCount(): any {
    this.cartCount = +(localStorage.getItem('cartCount'));
    return localStorage.getItem('cartCount');
  }

  // get navToCheckout(): any {
  //   if(sessionStorage.getItem('nav'))
  //     this.navigateToCheckout = sessionStorage.getItem('nav');
  //   console.log('navigateToCheckout',this.navigateToCheckout);
  //   return this.navigateToCheckout;
  // }

  get userInfo(): any {
    if (localStorage.getItem('LScurrentUser')) {
      // this.userName = JSON.parse(localStorage.getItem('LScurrentUser')).FirstName + ' ' + JSON.parse(localStorage.getItem('LScurrentUser')).LastName;
      //this.cartCount = +(localStorage.getItem('cartCount'));
      // this.userImage = JSON.parse(localStorage.getItem('LScurrentUser')).ProfileImage;
      // return this.userName;
      return JSON.parse(localStorage.getItem('LScurrentUser')).FirstName;
    }
  }

  navigateToSeller() {
    localStorage.setItem('BuyerSide', 'false');
    this.utilMethods.showFullScreenLoader();
    this._router.navigate(['/merchant/dashboard']);
  }

  becomeSeller() {
    this.utilMethods.showFullScreenLoader();

    if (this.merchantId == "00000000-0000-0000-0000-000000000000") {
      let cartDetails = {
        iconPath: '../../../../assets/icons/icon-192x192.png',
        heading: 'become_seller',
        primaryBtnText: 'yes',
        secondaryBtnText: 'no'
      }
      // tslint:disable-next-line: max-line-length
      this.modalRef = this.modalService.open(ModelComponent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: 'custom-class' });
      this.utilMethods.hideFullScreenLoader();
      this.modalRef.componentInstance.CartDetails = cartDetails;

      this.modalRef.componentInstance.submitEvent.subscribe(el => {
        this.utilMethods.hideFullScreenLoader()
        this.dashboardService.becomeSeller(this.userId).subscribe(res => {
          if (res.IsSuccess) {
            //console.log("MerchantCreated:", res.Data);
            this._userService.setCurrentUser(res.Data);
            this.utilMethods.showFullScreenLoader
            this._router.navigate(['/merchant/shop/add']);

          }
          //this.utilMethods.hideFullScreenLoader();
        })
        //   if (res) {
        //     this.cartAkshaakDelItems = [];
        //     this.cartShopDelItems = [];
        //     this.cartCustomerPickupItems = [];
        //     this.cartItemsDetail = [];
        // //     this.loadCartItems();
        //   }
        // })
        this.modalRef.close();
      })
      this.modalRef.componentInstance.closeEvent.subscribe(el => {
        this.modalRef.close();
      })
    } else if(JSON.parse(localStorage.getItem('LScurrentUser')).IsVerifiedOTP) {
      this._router.navigate(['/merchant/shop/add']);
    }else{
      this._router.navigate(['/auth/otp'], { skipLocationChange: true });
    }
  }

  sendCategoryId($event, item) {
    //console.log("Category Id: ", item);
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate(['/consumer/product/list'], {
        queryParams: { categoryId: item }
      });
    });
    //   this._router.navigate(['/consumer/product/list'],{ queryParams: {categoryId : item}
    // });
  }

  // navbarClick() {
  //   alert();
  // }

  value: any;

  translate() {
    this.i18N.currentLanguage == 'ae' ? this.langFlag = 'en' : this.langFlag = 'ae';
    this.utilMethods.showFullScreenLoader();

    this.i18N.setLanguage(this.langFlag);
    this.layoutService.onRtlCus(this.langFlag);
    this.utilMethods.hideFullScreenLoader()
  }

  async showPopup() {
    let msgs: any = await this._translate.get(['logout', 'logout_msg', "yes", "no"]).toPromise<void>();
    this.notificationService.smartMessageBox({
      title: "<i class='fa fa-sign-out txt-color-orangeDark'></i> " + msgs.logout + " <span class='txt-color-orangeDark'><strong>" + $('#show-shortcut').text() + "</strong></span> ?",
      content: msgs.logout_msg,
      buttons: `[${msgs.no}][${msgs.yes}]`

    }, (ButtonPressed) => {
      if (ButtonPressed == msgs.yes) {
        this.logout()
      }
    });
    if (window.innerWidth <= 991) {
      let el: HTMLElement = this.Navhelper.nativeElement;
      el.click();
    }
  }

  navigateToLogin() {
    this.signInPopup = true;
    //sessionStorage.setItem('returnUrl', '1');
    //this._router.navigate(['/auth/login']);
  }

  logout() {
    this._userService.logout();
    this.merchantId = undefined;
    this.storeId = undefined;
    this.userId = undefined;
    this.userImage = undefined;
    this.userName = undefined;
    this.checkUserInfo();
    if(this._router.url == '/'){
      this._router.navigateByUrl('/123', { skipLocationChange: true }).then(() => {
        this._router.navigate(['/']);
      });
    } else {
      this._router.navigate(['/']);
    }
  }

  handleSearch(event,isEnter?) {
    this.searchLoading = true;
    // console.log(event.target.value);
    let value = event.target.value;
    // // data is replaced with the filtered set only after 3 characters are typed
    if (value.length >= 1 && isEnter == undefined) {
      this.dashboardService.getSearchList(value).subscribe(res => {
        // console.log(res);
        if (res.IsSuccess){
          this.data = [];
          this.data = res.Data;
        }
          this.searchLoading = false;
        this.autocomplete.open()
      })
      //this.data = this.filteredArray.filter((s: any) => s.FilterNames.toLowerCase().indexOf(value.toLowerCase()) !== -1);

      // value = event.target.value;
    } else if(value.length >= 1 && isEnter != undefined){
      let navigationExtras: NavigationExtras = {
        queryParams: { "FilterName": event.target.value }
      };

      if (localStorage.getItem('advanceFilter')) {
        localStorage.removeItem('advanceFilter');
      }

      let filterName = { "FilterNames": event.target.value }

      this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this._router.navigate(['/consumer/product/list'], {
          queryParams: { ...filterName }
        })
      });
      
    } else {
      // value = event.target.value;
      this.data = [];
      //  this.autocomplete.close()
    }
    // this.value = value;

  }

  onSearch(event) {
    if (this.selectedSearch) {
      // let navigationExtras: NavigationExtras = {
      //   queryParams: { "FilterName": this.selectedSearch }
      // };
      // console.log(event);
      //console.log(this.selectedSearch);
      if (localStorage.getItem('advanceFilter')) {
        localStorage.removeItem('advanceFilter');
      }
      this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this._router.navigate(['/consumer/product/list'], {
          queryParams: { ...event }
        })
      });

    }
  }

  OnOpen() {
    //console.log("OnOpen");
    if (!this.isbeingSearched) {
      this.autocomplete.close()
    }

  }

  OnSearch() {
    this.isbeingSearched = true;
    //console.log("OnSearch");
    this.autocomplete.open()
  }

  OnBlur() {
    //console.log("OnBlur");
    this.isbeingSearched = false;
    this.autocomplete.close()
  }

  viewCartItems() {
    this.navigateToCheckout = sessionStorage.getItem('nav');
    //console.log('navigateToCheckout', this.navigateToCheckout);
    if (sessionStorage.getItem('nav') && this.navigateToCheckout == '0')
      this._router.navigate(['/consumer/checkout/checkoutTemplate']);
    else if (sessionStorage.getItem('nav') && this.navigateToCheckout != '0') {
      this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this._router.navigate(['/consumer/checkout/checkoutTemplate'])
      });
    } else {
      this._router.navigate(['/consumer/checkout/checkoutTemplate']);
    }


    // if (this.currentUser) {
    //   if (this.cartCount <= 0) {
    //     let cartDetails = {
    //       iconPath: "../../../../assets/img/empty-cart.svg",
    //       heading: "Your shopping cart is empty",
    //       primaryBtnText: "Continue Shopping"
    //     }
    //     this.modalRef = this.modalService.open(ModelComponent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: 'custom-class' });
    //     this.modalRef.componentInstance.CartDetails = cartDetails;

    //     this.modalRef.componentInstance.closeEvent.subscribe(el => {
    //       this._router.navigate(['/']);
    //       this.modalRef.close();
    //     })
    //   } else {
    //     this._router.navigate(['/consumer/checkout/checkoutTemplate']);
    //   }
    // } else {
    //   let modalDetails = {
    //     type: 'guestUser',
    //     iconPath: "../../../../assets/img/default_user.png",
    //     heading: "welcome_akshaak",
    //     subs: "guest_signin_msg",
    //     //delType: this.cartProductDetail.DeliveryType,
    //     //previousDelType: this.cartProductDetail.PreviousDeliveryType,
    //     primaryBtnText: "sign_in",
    //     secondaryBtnText: "create_account",
    //     skipBtnText: "skip_now"
    //   }
    //   this.modalRef = this.modalService.open(ModelComponent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: 'changePickupModal custom-class' });
    //   this.modalRef.componentInstance.CartDetails = modalDetails;
    //   this.modalRef.componentInstance.submitEvent.subscribe(el => {
    //     this._router.navigate(['/auth/login']);
    //     this.modalRef.close();
    //   })
    //   this.modalRef.componentInstance.registerEvent.subscribe(el => {
    //     this._router.navigate(['/auth/register']);
    //     this.modalRef.close();
    //   })
    //   this.modalRef.componentInstance.closeEvent.subscribe(el => {
    //     //this._router.navigate(['/consumer']);
    //     this.modalRef.close();
    //   })
    // }

  }

  getCustomerSupport() {
    this.modalRef = this.modalService.open(SupportComponent, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: 'custom-class' });
    this.modalRef.componentInstance.closeEvent.subscribe(el => {
      this.modalRef.close();
    })
  }

  checkValidation() {
    this.utilMethods.markControlsTouched(this.userForm);
    // if (this.userForm.controls.password.value != this.userForm.controls.cpassword.value)
    //   return;
    //     let abc = this.userForm.controls.phoneNumber.value.substring(0, 3);
    // if (this.userForm.controls.phoneNumber.value.substring(0, 3) != 971)
    // this.userForm.controls.phoneNumber.setErrors({'incorrect': true});

    if (!this.userForm.valid) {
      setTimeout(function () {
        document.querySelector('.has-error').scrollIntoView({
          behavior: 'smooth'
        });
      }, 100);
      return;
    }


    let userDetails = {
      objUserEntity: {
        FirstName: this.userForm.get('firstName').value,
        LastName: this.userForm.get('lastName').value,
        Email: this.userForm.get('email').value,
        Password: this.userForm.get('password').value,
        FacebookUserId: localStorage.getItem('facebookId') ? localStorage.getItem('facebookId') : '',
        GoogleUserId: localStorage.getItem('googleId') ? localStorage.getItem('googleId') : ''
      },
      objMerchantEntity: {
        UserID: "",
        merFirstName: this.userForm.get('firstName').value,
        merEmail: this.userForm.get('email').value,
        merPassword: this.userForm.get('password').value,
      }
    }

    //console.log('checkBox', this.userForm.get('seller').value);
    if (!this.userForm.get('seller').value) {
      userDetails.objMerchantEntity = null;
    }
    this.addRegistration(userDetails);
  }


  async addRegistration(data) {
    this.utilMethods.showFullScreenLoader();
    this._userTokenService.addRegistration(data)
      .subscribe(async srSuccess => {
        this.utilMethods.hideFullScreenLoader();
        if (srSuccess.IsSuccess == false) {
          let msg = await this._translate.get('already_account_exists').toPromise<void>();
          this.utilMethods.notificationDataError(msg);
          return;
        }
        else {
          localStorage.setItem('UserID', srSuccess.Data.UserID)
          // let msg = await this._translate.get('success_register').toPromise<void>();
          // this.utilMethods.notificationSuccess(msg);
          //console.log("UserSignUp:",srSuccess.Data)
          this._userService.setCurrentUser(srSuccess.Data);
          localStorage.setItem("Token", srSuccess.Data.Token)
          this.utilMethods.showFullScreenLoader();
          this.checkUserInfo();
          let route = this._router.url;
          if(this._router.url != '/'){
            this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this._router.navigate([route])
            });
          }else{
            this._router.navigateByUrl('/123', { skipLocationChange: true }).then(() => {
              this._router.navigate([route]);
            });
          }
          this.signupPopup = false;
          //this._router.navigate(['/auth/otp'], { skipLocationChange: true });

        }
      }, srError => {
        this.utilMethods.hideFullScreenLoader();
        this.utilMethods.notificationError(srError);
      });
  }

  async login(event) {
    this.clear();
    event.preventDefault();
    this.utilMethods.markControlsTouched(this.loginForm);
    if (!this.loginForm.valid) {
      return;
    }

    this.utilMethods.showFullScreenLoader();
    this._userTokenService.getLoginInfoDetails({
      Email: this.loginForm.get('email').value.toLowerCase(),
      Password: this.loginForm.get('password').value
    }).subscribe(async srSuccess => {
      this.utilMethods.hideFullScreenLoader();
      // this.activatedRoute.queryParams.subscribe(params => {
      //   this.redirectURL = params['returnUrl'];
      // });
      if (srSuccess.StatusCode == 200) {

        if (srSuccess.Data.UserID == "00000000-0000-0000-0000-000000000000") {
          this.failurePopup = true;
          // let msg = await this._translate.get('invalid_email_password').toPromise<void>();
          // this.utilMethods.notificationError(msg);
          return;
        }
        localStorage.setItem("UserID", srSuccess.Data.UserID)
        localStorage.setItem("Token", srSuccess.Data.Token);
        if (srSuccess.Data.IsVerifiedOTP == false) {
          let userInfo = {
            PhoneNo : srSuccess.Data.PhoneNo,
            Email : srSuccess.Data.Email,
            FirstName : srSuccess.Data.FirstName
          }
          localStorage.setItem("OtpFailed", JSON.stringify(userInfo))
          // this._userTokenService.regMobileNo = srSuccess.Data.PhoneNo;
          // this._userTokenService.regEmail = srSuccess.Data.Email;
          // this._userTokenService.regName = srSuccess.Data.FirstName;
          this._router.navigate(['/auth/otp']);
        }
        // else if (srSuccess.Data.StoreID == "00000000-0000-0000-0000-000000000000") {
        //     srSuccess.Data.PhoneNo = '+' + srSuccess.Data.PhoneNo;
        //     this._userService.setCurrentUser(srSuccess.Data);
        //     this._router.navigate(['/merchant/shop/add']);
        // }
        //Redirect to the url that was hit after login
        else if (this.redirectURL) {
          //console.log('UserData1', srSuccess.Data);
          //srSuccess.Data.PhoneNo = srSuccess.Data.PhoneNo;
          this._userService.setCurrentUser(srSuccess.Data);
          // sessionStorage.removeItem('anonymous-key');
          this._router.navigate([this.redirectURL]);
        }
        else if (sessionStorage.getItem('returnUrl')) {
          //console.log('UserData1', srSuccess.Data);
          //srSuccess.Data.PhoneNo = srSuccess.Data.PhoneNo;
          this._userService.setCurrentUser(srSuccess.Data);
          //this._router.navigate([this.redirectURL]);
          // sessionStorage.removeItem('anonymous-key');
          this._location.back();
        }
        else {
          //console.log('UserData2', srSuccess.Data);
          //srSuccess.Data.PhoneNo = srSuccess.Data.PhoneNo;
          this._userService.setCurrentUser(srSuccess.Data);
          // sessionStorage.removeItem('anonymous-key');
          this.utilMethods.showFullScreenLoader();
          this.checkUserInfo();
          // console.log(this._router.url);
          let route = this._router.url;
          let routeArr = route.split('?');
          // console.log("SomeOne:",routeArr);
          if(routeArr.length == 1){
            if(route == "/consumer/checkout/AddAddressTemplate/Guest"){
              sessionStorage.setItem('Step','1');
              this._router.navigate(['/consumer/checkout/checkoutTemplate']);
            } else if(this._router.url != '/'){
              this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this._router.navigate([route])
              });
            } else {
              this._router.navigateByUrl('/123', { skipLocationChange: true }).then(() => {
                this._router.navigate([route]);
              });
            }
          }else{
            this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this._router.navigate([routeArr[0]], {
                queryParams: { categoryId: routeArr[1].split('=')[1] } 
              });
            });
          }
          

          //this._router.navigate(['/']);
          this.signInPopup = false;
        }


      }
    }, async srError => {
      this.utilMethods.hideLoader();
      let msg = await this._translate.get('network_error').toPromise<void>();
      this.utilMethods.notificationError(msg);
    });
  }

  validate(event) {
    var ew = event.which;
    if (48 <= ew && ew <= 57)
      return true;
    if (64 <= ew && ew <= 90)
      return true;
    if (97 <= ew && ew <= 122)
      return true;
    if (ew == 45 || ew == 46 || ew == 95)
      return true;

    return false;
  }

  fbLogin() {
    this.clear();
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then((userData) => {
      console.log("Facebook:",userData);
      this.user = userData;
      if (userData.id)
        this.getUserDetails(userData.email, userData.name);
      localStorage.setItem('facebookId', this.user.id)
    });
  }

  googleLogin() {
    this.clear();
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
      console.log("google:",userData);
      this.user = userData;
      if (userData.id)
        this.getUserDetails(userData.email, userData.name);
      localStorage.setItem('googleId', this.user.id)
      localStorage.setItem('googleEmail', this.user.email)
    });
  }

  getUserDetails(email, username) {
    this._userService.getUserDetails(email).subscribe((srSuccess: any) => {
      if (srSuccess.StatusCode == 217){
        let userDetails = {
          objUserEntity: {
            FirstName: username,
            Email: email,
            Password: null,
            FacebookUserId: localStorage.getItem('facebookId') ? localStorage.getItem('facebookId') : '',
            GoogleUserId: localStorage.getItem('googleId') ? localStorage.getItem('googleId') : ''
          },
          objMerchantEntity: null
        }
        this.addRegistration(userDetails);
        this.signInPopup = false;
        //this._router.navigate(['/auth/register'])
    }else {
        this._userService.setCurrentUser(srSuccess.Data);
        this.checkUserInfo();
          //console.log(this._router.url);
          let route = this._router.url;
          if(this._router.url != '/'){
            this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this._router.navigate([route])
            });
          }else{
            this._router.navigateByUrl('/123', { skipLocationChange: true }).then(() => {
              this._router.navigate([route]);
            });
          }
          this.signInPopup = false;
          this.signupPopup = false;
        //this._router.navigate(['/'])
      }

    });
  }

  clear() {

    localStorage.removeItem('googleId');
    localStorage.removeItem('facebookId');
    localStorage.removeItem('googleEmail');
  }


}
